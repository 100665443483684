import "antd/dist/antd.css";
import "./App.css";
import Point from "./components/Points/Point";
import AddPoints from "./components/Points/AddPoints";

import {
  ProtectedRoute,
  IsLoggedIn
} from "./components/Authentication/protectedRoute";

import MainDash from "./components/home/MainDash";

import Dashboard from "./components/dashboard/DashboardLayout";

import UserListMain from "./components/users/UserListMain";
import ViewUserData from "./components/users/ViewUserData";
import EditUser from "./components/users/EditUser";

import DriverListMain from "./components/drivers/DriverListMain";
import ViewDriver from "./components/drivers/ViewDriver";
import AddDrivers from "./components/drivers/AddDrivers";

import RestaurantsListMain from "./components/restaurants/RestaurantsListMain";
import AddRestaurants from "./components/restaurants/AddRestaurants";
import ViewRestaurantData from "./components/restaurants/ViewRestaurantData";
import EditRestaurantData from "./components/restaurants/EditRestaurantData";
import OrderPerRestaurant from "./components/restaurants/OrderPerRes";

import Oppgjor from "./components/OPPGJØR/Oppgjor";

import AllReports from "./components/reports/AllReports";

import Citylist from "./components/cities/CityList";
import AddCity from "./components/cities/AddCity";
import ViewCity from "./components/cities/ViewCity";

import AllOrderHistory from "./components/orderhistory/AllOrderHistory";
import UserOrderHistory from "./components/users/UserOrderHistory";

import ListAdminIcon from "./components/listadminicon/ListAdminIcon";
import AddAdminIcon from "./components/listadminicon/AddAdminIcon";

import EmptyPage from "./components/EmptyPage";

//for commit only delete it
import { Routes, Route } from "react-router-dom";

import Login from "./components/Authentication/login/Login";
import Setting from "./components/Settings/Setting";
import TFLPoints from "./components/TFLPoints/TFLPoints";
// import Donation from "./components/Donation/Donation";
import ViewTflProd from "./components/TFLPoints/ViewTflProd";
import AddDelivery from "./components/postenCharges/AddDelivery";
import PostCharges from "./components/postenCharges/PostCharges";
import EditDelivery from "./components/postenCharges/EditDelivery";
import TFLOrders from "./components/tflOrders/TFLOrders";
import ViewTfl from "./components/tflOrders/ViewTfl";
import Daily from "./components/tflReports/DailyReports/Daily";
import GroupReport from "./components/tflReports/GroupReport/GroupReport";
import Single from "./components/tflReports/DailyReports/Single";

function App() {
  return (
    <div className="App">
      <Routes>
        {/*-----------------------Login----------------------*/}
        <Route
          exact
          path="/"
          element={
            <IsLoggedIn>
              <Login />
            </IsLoggedIn>
          }
        />
        <Route exact path="/afterlogin" element={<MainDash />} />

        {/*--------------------------------MainBody-------------------------------*/}

        {/*-----------------------Dashboard----------------------*/}
        <Route
          exact
          path="/dashboard"
          element={
            <ProtectedRoute>
              <MainDash Component={Dashboard} />
            </ProtectedRoute>
          }
        />

        {/*-----------------------User----------------------*/}
        <Route
          exact
          path="/userlistmain"
          element={
            <ProtectedRoute>
              <MainDash Component={UserListMain} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/edituserdata"
          element={
            <ProtectedRoute>
              <MainDash Component={EditUser} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/viewuser"
          element={
            <ProtectedRoute>
              <MainDash Component={ViewUserData} />
            </ProtectedRoute>
          }
        />

        {/*-----------------------Driver----------------------*/}
        <Route
          exact
          path="/driverlistmain"
          element={
            <ProtectedRoute>
              <MainDash Component={DriverListMain} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/adddrivers"
          element={
            <ProtectedRoute>
              <MainDash Component={AddDrivers} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/viewdriver"
          element={
            <ProtectedRoute>
              <MainDash Component={ViewDriver} />
            </ProtectedRoute>
          }
        />

        {/*-----------------------Restaurant----------------------*/}
        <Route
          exact
          path="/restaurantlistmain"
          element={
            <ProtectedRoute>
              <MainDash Component={RestaurantsListMain} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/addrestaurants"
          element={
            <ProtectedRoute>
              <MainDash Component={AddRestaurants} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/viewrestaurantdata"
          element={
            <ProtectedRoute>
              <MainDash Component={ViewRestaurantData} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/editrestaurantdata"
          element={
            <ProtectedRoute>
              <MainDash Component={EditRestaurantData} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/ordersperrestaurant"
          element={
            <ProtectedRoute>
              <MainDash Component={OrderPerRestaurant} />
            </ProtectedRoute>
          }
        />
        {/*-----------------------Oppgjor----------------------*/}
        <Route
          exact
          path="/oppgjor"
          element={
            <ProtectedRoute>
              <MainDash Component={Oppgjor} />
            </ProtectedRoute>
          }
        />

        {/*-----------------------Reports----------------------*/}
        <Route
          exact
          path="/allreports"
          element={
            <ProtectedRoute>
              <MainDash Component={AllReports} />
            </ProtectedRoute>
          }
        />

        {/*-----------------------City----------------------*/}
        <Route
          exact
          path="/citylist"
          element={
            <ProtectedRoute>
              <MainDash Component={Citylist} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/addcity"
          element={
            <ProtectedRoute>
              <MainDash Component={AddCity} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/viewcity"
          element={
            <ProtectedRoute>
              <MainDash Component={ViewCity} />
            </ProtectedRoute>
          }
        />

        {/*-----------------------OrderHistory----------------------*/}
        <Route
          exact
          path="/orderhistory"
          element={
            <ProtectedRoute>
              <MainDash Component={AllOrderHistory} />
            </ProtectedRoute>
          }
        />

        {/*-----------------------USERS----------------------*/}
        <Route
          exact
          path="/orderhistoryuser"
          element={
            <ProtectedRoute>
              <MainDash Component={UserOrderHistory} />
            </ProtectedRoute>
          }
        />
        {/*-----------------------Empty Page----------------------*/}
        <Route
          exact
          path="/emptypage"
          element={
            <ProtectedRoute>
              <MainDash Component={EmptyPage} />
            </ProtectedRoute>
          }
        />
        {/*-----------------------setting Page----------------------*/}
        <Route
          exact
          path="/settings"
          element={
            <ProtectedRoute>
              <MainDash Component={Setting} />
            </ProtectedRoute>
          }
        />

        {/*-----------------------Add Icon----------------------*/}
        <Route
          exact
          path="/listadminiconview"
          element={
            <ProtectedRoute>
              <MainDash Component={ListAdminIcon} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/addadminicon"
          element={
            <ProtectedRoute>
              <MainDash Component={AddAdminIcon} />
            </ProtectedRoute>
          }
        />

        {/*------------------- Add Points calculation---------------------------*/}
        <Route
          exact
          path="/points"
          element={
            <ProtectedRoute>
              <MainDash Component={Point} />
            </ProtectedRoute>
          }
        />

          {/*------------------- Add Point conversions---------------------------*/}
         <Route
          exact
          path="/addPoints"
          element={
            <ProtectedRoute>
              <MainDash Component={AddPoints} />
            </ProtectedRoute>
          }
        />

         {/*-----------------------  Add TFLPoints ---------------------------------*/}
         <Route
          exact
          path="/tflPoints"
          element={
            <ProtectedRoute>
              <MainDash Component={TFLPoints} />
            </ProtectedRoute>
          }
        />
         <Route
          exact
          path="/viewTFL"
          element={
            <ProtectedRoute>
              <MainDash Component={ViewTflProd} />
            </ProtectedRoute>
          }
        />

 {/*-----------------------  Donation  ---------------------------------*/}
         {/* <Route
          exact
          path="/donation"
          element={
            <ProtectedRoute>
              <MainDash Component={Donation} />
            </ProtectedRoute>
          }
        /> */}

          {/*-----------------------  tfl posten charges  ---------------------------------*/}
          <Route
          exact
          path="/postenCharges"
          element={
            <ProtectedRoute>
              <MainDash Component={PostCharges} />
            </ProtectedRoute>
          }
        />
          <Route
          exact
          path="/addDeliveryCharges"
          element={
            <ProtectedRoute>
              <MainDash Component={AddDelivery} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/editDeliveryCharges"
          element={
            <ProtectedRoute>
              <MainDash Component={EditDelivery} />
            </ProtectedRoute>
          }
        />
        
         {/*-----------------------  tfl orders  ---------------------------------*/}
         <Route
          exact
          path="/tflOrders"
          element={
            <ProtectedRoute>
              <MainDash Component={TFLOrders} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/viewTFLOrders"
          element={
            <ProtectedRoute>
              <MainDash Component={ViewTfl} />
            </ProtectedRoute>
          }
        />
         {/*-----------------------  tfl reports  ---------------------------------*/}
         <Route
          exact
          path="/tflReport"
          element={
            <ProtectedRoute>
              <MainDash Component={Daily} />
            </ProtectedRoute>
          }
        />
          <Route
          exact
          path="/tflDailyReport"
          element={
            <ProtectedRoute>
              <MainDash Component={Single} />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/tflZReport"
          element={
            <ProtectedRoute>
              <MainDash Component={GroupReport} />
            </ProtectedRoute>
          }
        />
        </Routes>
        
    </div>
  );
}

export default App;
