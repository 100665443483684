  import {Row, Col, Input, Button, message, Form } from 'antd';
  import React from 'react';
  import { NavLink } from 'react-router-dom';
  
  
  
  const propsupload = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
  
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
  
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  /* eslint-disable no-template-curly-in-string */
  
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  /* eslint-enable no-template-curly-in-string */
  
  
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  
  const onSearch = (value) => {
    console.log('search:', value);
  };
  
  const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
  
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
  
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  
  
  export default function AddCity() {
    const onFinish = (values) => {
      console.log(values);
    };
  
        return (
          <>
            <div className='add-r-main bg-white pb-2'>
              <Row className='justify-content-between align-items-center add-r-head px-4 py-3'>
                <Col>
                  <h3>Add New City</h3>
                </Col>
                <Col>
                <NavLink to='/citylist' className="common-btn">Return to the List</NavLink>
                </Col>
              </Row>
              <div className='form-r-main p-3'>
              <Form {...layout} name="nest-messages" className='add-r-form p-3'>
              <Row>
                <Col className='col-12'>
                  <Form.Item name="city-name"
                  rules={[
                  {
                  required: true,
                  message: 'Please input City Name!',
                  },
                  ]}
                  >
                  <Input placeholder="City Name"/>
                  </Form.Item>
                </Col>
              </Row>
              
              <Row>
                <Col className='col-6'>
                <Form.Item name="longitude"
                rules={[
                {
                type: 'email',
                message: 'The input is not valid longitude!',
                },
                {
                required: true,
                message: 'Please input your longitude!',
                },
                ]}>
                <Input placeholder="Longitude"/>
                </Form.Item>
                </Col>
                <Col className='col-6'>
                <Form.Item name="laitude"
                rules={[
                {
                required: true,
                message: 'Please input your laitude!',
                },
                ]}>
                <Input placeholder="laitude"/>
                </Form.Item>
                </Col>
              </Row>
  
              <Row className='pt-3'>
                <Col className='col-12'>
                <Form.Item>
                  <Input.TextArea placeholder="Description" />
                </Form.Item>
                </Col>
              </Row>
  
              <Row className=' justify-content-center'>
                <Col className='col-6'>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
                <Button type="primary" htmlType="submit">Add</Button>
                </Form.Item>
                </Col>
              </Row>
            </Form>
              </div>
            </div>
          </>
        );
      };
  
  