import {
  Table,
  Space,
  Avatar,
  Image,
  Row,
  Col,
  Divider,
  Button,
  Input,
  Rate,
  Tag,
  Tooltip
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined
} from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchallUsers } from "./../../reducers/users/allUsersRed";
import api from "../../commonFuncs/api";

const mockVal = (str, repeat = 1) => ({
  value: str.repeat(repeat)
});

export default function UserListMain(props) {
  const [page, setPage] = useState(1);
  let navigate = useNavigate();
  const totalUsers = useSelector((state) => state.allUsersRed);
  // console.log("total users of the app=====>", totalUsers);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchAllUsers();
  }, []);
  const fetchAllUsers = async () => {
    try {
      // console.log("hitting the get users api=====>");
      let result = await api.get(`/api/v1/treeDriveAdmin/appuser/appusers`);
      // console.log("result in all users ====>", result);
      dispatch(fetchallUsers(result.data.data.users));
    } catch (err) {
      console.log("error--->", err.message);
    }
  };
  const columns = [
    {
      title: "S.No",
      key: "sno",
      width: "5%",
      fixed: "left",
      render: (value, item, index) => {
        return (page - 1) * 20 + (index + 1);
      }
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      width: "13%",
      fixed: "left",
      render: (record, item, index) => {
        return item?.firstName + " " + item?.lastName;
      }
    },
    {
      title: "E-mail",
      key: "email",
      width: "18%",
      render: (record, item, index) => {
        return item?.email;
      }
    },
    {
      title: "Phone Number",
      key: "phoneNumber",
      width: "14%",
      render: (record, item, index) => {
        return item?.phoneNumber;
      }
    },
    { 
      title: "City",
      key: "city",
      width: "14%",
      render: (record, item, index) => {
        return item?.address?.city;
      }
    },
    // {
    //   title: "Total Orders",
    //   key: "totalOrders",
    //   width: "10%",
    //   render: (record, item, index) => {
    //      return record.totalOrders;
    //   }
    // },
    {
      title: "Total Orders",
      key: "totalOrders",
      // render: (record, item, index) => {
      //   return item.totalOrders;
      // },
      dataIndex: "totalOrders",
      defaultSortOrder: "descend", //'descend' , //'ascend'
      sorter: (a, b) => a.totalOrders - b.totalOrders
    },
    {
      title: "Created At",
      key: "createdAt",
      width: "10%",
      sorter: (a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      },
      sortDirections: ["ascend", "descend"],
      render: (record, item, index) => {
        return new Date(item.createdAt).toLocaleDateString();
      }
    },
    {
      title: "isUser Verified",
      key: "isVerifiedUser",
      width: "10%",
      render: (text, record) => {
        let color;
        color = record.isVerifiedUser ? "green" : "red";
        return <Tag color={color}>{record.isVerifiedUser ? "YES" : "NO"}</Tag>;
      }
    },
    {
      title: "Action",
      key: "action",
      width: "9%",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="View" placement="left" color={"blue"}>
            <span>
              <NavLink
                to="/viewuser"
                state={{ singleUser: record }}
                className=""
              >
                <EyeOutlined />
              </NavLink>
            </span>
          </Tooltip>
        </Space>
      ),
      width: "8%"
    }
  ];

  return (
    <>
      <div className="user-list">
        <Row>
          <Col>
            <h4>List of Users : {totalUsers.length}</h4>
          </Col>
        </Row>
        <Row>
          <Table
            className="user-table"
            columns={columns}
            dataSource={totalUsers}
            striped
            bordered
            hover
            //pagination={{ pageSize: 10 }}
            pagination={{
              // position:['topRight'],
              pageSize: 20,
              onChange(current) {
                setPage(current);
              }
            }}
            // size="middle"
            // scroll={{
            //   x: "calc(700px + 20%)",
            //   y: 640
            // }}
          />
        </Row>
      </div>
    </>
  );
}
