import { 
    Table, 
    Space,
     Row,
     Col,
      Rate,
      Avatar,
      Image,
      Tag,
      Input,
      Button,
      Tooltip
    } from 'antd';
  import {
    EyeOutlined,
    EditOutlined,
    DeleteOutlined,
  SearchOutlined

  } from '@ant-design/icons';

  import React, {useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
  
  // const mockVal = (str, repeat = 1) => ({
  //   value: str.repeat(repeat),
  // });
  
  export default function CityList(props) {


    
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  
  
      const columns = [
        {
          title: 'S.No',
          dataIndex: 'sno',
          key: 'sno',
        },
          {
            title: 'City Name',
            dataIndex: 'cityname',
            key: 'cityname',
            ...getColumnSearchProps('cityname'),
          },
          {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
          },
          {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Space size="middle">
              <Tooltip title="View" placement="left" color={'green'}>
    <span><NavLink to='/addcity'><EditOutlined /></NavLink></span>
  </Tooltip>
             <Tooltip title="View" placement="top" color={'blue'}>
    <span><NavLink to='/viewcity'><EyeOutlined /></NavLink></span>
  </Tooltip>
             <Tooltip title="Delete" placement="bottom" color={'red'}>
    <span><a><DeleteOutlined /></a></span>
  </Tooltip>
                
                
              </Space>
            ),
          },
        ];
        const data = [
          {
            sno: '1',
            cityname:'Moss',
            longitude: '123454',
            latitude: '789565',
            action: 'Edit View Delete',
          },
          {
            sno: '2',
            cityname:'Askim',
            longitude: '123454',
            latitude: '789565',
            action: 'Edit View Delete',
          },
          
        ];
        return (
          <>
          <div className='user-list'>
          <Row className='justify-content-between'>
          <Col>
          <h4>List of Cities</h4>
          </Col>
          <Col>
          {/* <Button type="primary">Add Drivers</Button> */}
          <NavLink to='/addcity' className="common-btn">Add City</NavLink>
          </Col>
          </Row>
          <Row>
          <Table className='user-table' columns={columns} dataSource={data} striped bordered hover pagination={{
      pageSize: 10,
    }}/>
          </Row>
          </div>
          </>
        );
      };
  
  