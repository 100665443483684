import React,{useState}  from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import api from "./../../../commonFuncs/api";
import Container from 'react-bootstrap/Container';
import { Form, Input, Button,} from 'antd';
import Col from 'react-bootstrap/Col';
import logo from "../../../assets/logo.png"
import ForgotPassword from "./ForgotPassword";

import endpoint from "../../../helpers/Endpoint";

import openNotification from "../../../commonFuncs/notification";

const Login=(props)=> {
  let navigate=useNavigate();
 
  let inputFormValue={
    username:"",
    password:""
  }
 let errObj={
  username:false,
  password:false,
 }
const [inputForm,setInputForm]=useState(inputFormValue)
const [errorObj,setErrorObj]=useState(errObj);
const [isModalVisible, setIsModalVisible] = useState(false);
const [loading,setLoading]=useState(false);
const token=localStorage.getItem("token");


const onFinish = (values) => {
  console.log('Success:', values);
 };
    
const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
 }
      
      const showModal = () => {
        setIsModalVisible(true);
      };
     
      const modalClose = () => {
        setIsModalVisible(false)
        
      }

  const loginHandle=async(e)=>{
      e.preventDefault();
      console.log("inside the loginHandle---->");
      let formValidation=true;
      for(let key in inputForm){
        console.log("inside the for loop of login formValidation------>",formValidation)
       if(inputForm[key]==""){
        setErrorObj((prevObj)=>({...prevObj,[key]:true}));
        formValidation=false;
       }
       else{
        setErrorObj((prevObj)=>({...prevObj,[key]:false,formValidation:true}))
       }
     }

  
     
     
   
     let result;
     console.log("email in login----->",inputForm["username"])
     console.log("formValidation in login------>",formValidation);
     if(formValidation){
      setLoading(true);
      try{
        result=await axios.post(`${endpoint}/api/v1/treeDriveAdmin/appadmin`,{
         email:inputForm.username,
         password:inputForm.password
       })
      // result=await api.post(`${endpoint}/api/v1/treeDriveAdmin/appadmin`,{
      //   email:inputForm.username,
      //   password:inputForm.password
      // })
      }catch(err){
        console.log("errr----->",err.message);
        openNotification("error","Error Occured",err.message)
      }
     }
    
     

     if(result && result.data.status===200){
       setLoading(false)
      let {token}=result.data.data;
      localStorage.clear();
      localStorage.setItem("token",token);
      openNotification("success","Logged In","Successfully Logged In")
      navigate("/dashboard");
     }else if(result && result.data.status!=200){
      openNotification("error","Error Occurred!!",result.data.message)
      setLoading(false)
     }
    }
  
    return (
      <>
      <ForgotPassword  visible={isModalVisible} modalClose={modalClose}/>
        <Container className="pageWrapper">
         <Col className="pageContainer">
                    <div className="content-wrapper">
                    <img src={logo} style={{width:"300px"}}/>

                    </div>
        </Col>    
       <Col className="pageContainerRight" >
               <Form className="form-wrapper"
                    name="basic"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
    >
          {/* <img src={logo} style={{width:"120px"}} className="logoMobile"/> */}
      <Form.Item 
        label="Username"
        name="username"
        placeholder="Select a option and change input text above"
        value={inputForm.username} 
        onChange={(e)=>{
          console.log(e.target.value)
          setInputForm((prevState)=>({
            ...prevState,username:e.target.value
          }))
          }} 
          rules={[
            {

               required: true,
            },
          ]}
      >
        
        <Input />
        {errorObj.username?<span style={{color:"red"}}>Fill user Name</span>:""}
      </Form.Item>

      <Form.Item className="password"
        label="Password"
        name="password"
        value={inputForm.password} 
        onChange={(e)=>{
          console.log(e.target.value)
          setInputForm((prevState)=>({
            ...prevState,password:e.target.value
          }))
          }}
          rules={[
            {
              type: 'email',
               required: true,
            },
          ]}
      >
        <Input.Password />
        {errorObj.password?<span style={{color:"red"}}>Fill Password</span>:""}
      </Form.Item>
        <div className="passwordContainer">
        
        <Button type="primary" htmlType="submit" onClick={loginHandle} loading={loading}>
        Login
        </Button>
        <Button  style={{color:"#32A2EC"}} onClick ={showModal} className="submitContainer">Forgot Password?</Button>
        </div>
      {/* <Form.Item className="login"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" onClick={loginHandle} loading={loading}>
        Login
        </Button>
      </Form.Item> */}
    </Form>
  </Col>
</Container>
     
       </>
      );
    }

export default Login;