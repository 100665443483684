import React,{useEffect,useState} from 'react'

import DashCardMapping from './DashCardMapping'
import GraphMain from './GraphMain'

import {Divider  } from 'antd';
//start
// import React,{useEffect,useState} from "react";
import { useDispatch,useSelector } from "react-redux";
import api from "../../commonFuncs/api";
import {fetchDashBoard} from "./../../reducers/dashboard/dashboardRed";

import moment from "moment";



//end

export default function Dashboard() {
  const [dateSelected,setDateSelected]=useState(moment(new Date()));
  const [startDate,setStartDate]=useState(moment(dateSelected.startOf("day").toString()).format())
  const [endDate,setEndDate]=useState(moment(dateSelected.endOf("day").toString()).format())
  const [startOfMonth,setStartOfMonth]=useState(moment(dateSelected.startOf("month").toString()).format())
  const [endOfMonth,setEndOfMonth]=useState(moment(dateSelected.endOf("month").toString()).format());
  const [startOfWeek,setStartOfWeek]=useState(moment(new Date()).startOf("isoWeek").format())
  const [endOfWeek,setEndOfWeek]=useState(moment(new Date()).endOf("isoWeek").format())
  const [startOfYear,setStartOfyear]=useState(moment(dateSelected.startOf("year").toString()).format())
  const [endOfYear,setEndOfYear]=useState(moment(dateSelected.endOf("year").toString()).format())
  const [loaded,setLoaded]=useState(false);
    const dashBoardData=useSelector((state)=>state.dashboardRed);
  const dispatch=useDispatch();
  useEffect(()=>{
      fetchDashBoardData()
  },[]);
  const fetchDashBoardData=async()=>{
      try{
          let result=await api.post(`/api/v1/treeDriveAdmin/appdashboard/appdashboard`,{
            startDate:startDate,
            endDate:endDate,
            startOfWeek:startOfWeek,
            endOfWeek:endOfWeek,
            startOfMonth:startOfMonth,
            endOfMonth:endOfMonth,
            startOfYear:startOfYear,
            endOfYear:endOfYear
          });
          console.log("result in dashboard data ====>",result);
          dispatch(fetchDashBoard(result.data.data));
          setLoaded(true);

        }catch(err){
          console.log("error in total order--->",err.message);
        }
  }
  console.log("loaded in main body====>",loaded)
  return (
    <>
     <DashCardMapping/>
      <Divider></Divider>
      {loaded ? <GraphMain /> : ""}
      <Divider></Divider>
    </>
  );
}
