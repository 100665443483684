import {Row, Col, Descriptions,Image, Avatar,Badge } from 'antd';
  import React from 'react';
  import { NavLink } from 'react-router-dom';

export default function ViewCity() {
  return (
    <>
        <div className='add-r-main bg-white pb-2'>
          <Row className='justify-content-between align-items-center add-r-head px-4 py-3'>
            <Col>
              <h3>City's Data</h3>
            </Col>
            <Col className='col-3 flex justify-content-end'>
            <NavLink to='/citylist' className="common-btn">Return to the List</NavLink>
            </Col>
          </Row>
          <div className='p-5'>
            <Row className=' flex flex-column'>
            <div className='display-data p-5 flex flex-column  justify-content-center'>
            
      <Descriptions  layout="vertical"  bordered className='pt-3'>
          <Descriptions.Item label="City Name" span={2}>Moss</Descriptions.Item>
          <Descriptions.Item label="Longitude">123456</Descriptions.Item>
          <Descriptions.Item label="Latitude" span={2}>789456</Descriptions.Item>
          <Descriptions.Item label="Country">India</Descriptions.Item>
          <Descriptions.Item label="Discription">Talk with someone who speaks a different language. Speak       with Google Translate · For signs, menus, etc, just point your camera and get an instant      translation.
          </Descriptions.Item>
      </Descriptions>
            </div>
            </Row>
          </div>
          </div>
    </>
  )
}
