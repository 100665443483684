import React,{useEffect,useState} from "react";
import { useDispatch,useSelector } from "react-redux";
import{
    MdAutoGraph,
    MdRestaurantMenu
} from 'react-icons/md';

import{
    FaUsers,
    FaCarSide
}from 'react-icons/fa';
import api from "../../commonFuncs/api";
import {fetchDashBoard} from "./../../reducers/dashboard/dashboardRed";
import TopCard from './TopCard'
import DashCardData from './DashCardData';
import DashCardDataa from './DashCardData';
import moment from "moment";
import startEndDay from "./../../commonFuncs/moment/endStart";
import formatDateTwoWeeks from "./../../commonFuncs/moment/endStartTwoWeeks";
export default function DashCardMapping() {
  // const [dateSelected,setDateSelected]=useState(moment(new Date()));
  // const [startDate,setStartDate]=useState(moment(dateSelected.startOf("day").toString()).format())
  // const [endDate,setEndDate]=useState(moment(dateSelected.endOf("day").toString()).format())
  const dashBoardData=useSelector((state)=>state.dashboardRed);
  // const dispatch=useDispatch();
  // useEffect(()=>{
  //     fetchDashBoardData()
  // },[]);
  // const fetchDashBoardData=async()=>{
  //     try{
  //       console.log("startDate in dashboard====>",startDate)
  //         let result=await api.post(`/api/v1/treeDriveAdmin/appdashboard/appdashboard`,{
  //           startDate:startDate,
  //           endDate:endDate
  //         });
  //         console.log("result in dashboard data ====>",result);
  //         dispatch(fetchDashBoard(result.data.data));
  //       }catch(err){
  //         console.log("error in total order--->",err.message);
  //       }
  // }
  const DashCardData=[
      {
          title:"Total Sales",
          text:dashBoardData.totalOrders,
          icon:<MdAutoGraph />
      },
      {
          title:"Total Users",
          text:dashBoardData.totalUsers,
          icon:<FaUsers />
      },
      {
          title:"Total Restaurants",
          text:dashBoardData.totalBranches,
          icon:<MdRestaurantMenu />
      },
      {
          title:"Total Drivers",
          text:dashBoardData.totalDrivers,
          icon:<FaCarSide />
      },
  ]
  return (
        <div className="flex justify-content-between topCardRow">
      {
                     DashCardData.map((val,ind)=>{
                        return <TopCard
                        title={val.title}
                        text={val.text}  
                        icon={val.icon}                  
                        />
                      })
                    }
      </div>  
  )
}
